import React from "react"
import cn from "classnames"

import css from "./Title.module.scss"

export const Title = ({ isNotFirstEnter }) => {
  return (
    <div className={cn(css.title, !isNotFirstEnter && css.titleWithCookie)} id="blastTitle">
      JASTER — KOMHATA
    </div>
  )
}
