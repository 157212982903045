export const beats = [
  { artist: "JASTER", name: "01 KOMHATA", order: 10, letter: "1" },
  { artist: "JASTER", name: "02 FONK GRAD", order: 20, letter: "2" },
  { artist: "JASTER", name: "03 POLY FONK", order: 30, letter: "3" },
  { artist: "JASTER", name: "04 SULTAN", order: 40, letter: "4" },
  { artist: "JASTER", name: "05 JAZZ FUN", order: 50, letter: "5" },
  { artist: "JASTER", name: "06 COTKA", order: 60, letter: "6" },
  { artist: "JASTER", name: "07 CTAKAH", order: 70, letter: "7" },
  { artist: "JASTER", name: "08 3PAC", order: 80, letter: "8" },
  { artist: "JASTER", name: "09 ZAGARA", order: 90, letter: "9" },
  { artist: "JASTER", name: "10 OTMEHA", order: 100, letter: "10" },
  { artist: "JASTER", name: "11 VODNIK", order: 110, letter: "11" },
  { artist: "JASTER", name: "00", order: 120, letter: "STOP" }
]
