import React, { useState, useCallback, useEffect } from "react"

import OutsideClickHandler from "react-outside-click-handler"
import useAudioController from "shared/hooks/useAudioController"

import Beats from "./components/Beats/Beats"
import { HomeFooter } from "pages/Home/components/HomeFooter/HomeFooter"
import { beats } from "shared/constants/beats.js"
import { HomeBackground } from "./components/HomeBackground/HomeBackground"
import { Title } from "./components/Title/Title"

export default function Home() {
  const [playingBeat, setPlayingBeat] = useState(null)
  const [isDesktop, setIsDesktop] = useState(null)
  const [tabbedLetter, setTabbedLetter] = useState(null)
  const [beat, setBeat] = useState(null)
  const outsideClick = useCallback(() => {
    setPlayingBeat(null)
  })

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setIsDesktop(true)
    }
    document.getElementById("appContainer").focus()
  }, [isDesktop])

  const { keyPress } = useAudioController({ beat, playingBeat, setPlayingBeat })

  const keyPressEvent = (event) => {
    setTabbedLetter(event.key)
    const res = beats.filter((e) => e.letter.toLocaleLowerCase() === event.key)
    if (res.length && beat !== res[0]) {
      setPlayingBeat(null)
      setBeat(res[0])
    }
  }

  useEffect(() => {
    keyPress()
  }, [tabbedLetter, beat])

  return (
    <div tabIndex={0} onKeyDown={keyPressEvent}>
      <HomeBackground isNotFirstEnter={true} keyPressEvent={keyPressEvent}>
        <Title isNotFirstEnter={true} />
        <OutsideClickHandler onOutsideClick={outsideClick}>
          <Beats playingBeat={playingBeat} isNotFirstEnter={true} setPlayingBeat={setPlayingBeat} beats={beats} />
        </OutsideClickHandler>
        <HomeFooter country={"RU"} isNotFirstEnter={true} />
      </HomeBackground>
    </div>
  )
}
