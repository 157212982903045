import React from "react"

import css from "./HomeFooter.module.scss"

export const DownloadLink = () => {
  return (
    <a target="_blank" href="https://jaster2.bandcamp.com/album/komhata" rel="noreferrer">
      <div className={css.downloadLink} id="downloadContainer">
        <div className={css.svgLogo}>
          <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1801_111)">
              <mask id="mask0_1801_111" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="32">
                <path
                  d="M16.5 32C25.6127 32 33 24.8366 33 16C33 7.16344 25.6127 3.8147e-06 16.5 3.8147e-06C7.38729 3.8147e-06 0 7.16344 0 16C0 24.8366 7.38729 32 16.5 32Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_1801_111)">
                <mask id="mask1_1801_111" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="32">
                  <path d="M0 32H33V3.8147e-06H0V32Z" fill="white" />
                </mask>
                <g mask="url(#mask1_1801_111)">
                  <path d="M0 32H33.3223V-0.312496H0V32Z" fill="#450505" />
                </g>
              </g>
              <mask id="mask2_1801_111" maskUnits="userSpaceOnUse" x="17" y="11" width="8" height="10">
                <path
                  d="M20.9356 14.9004C19.7529 14.9004 19.1486 15.803 19.1486 17.1629C19.1486 18.4483 19.802 19.4131 20.9356 19.4131C22.2175 19.4131 22.698 18.2755 22.698 17.1629C22.6971 16.001 22.0932 14.9004 20.9352 14.9004M17.793 11.5256H19.1979V14.7891H19.2227C19.6048 14.1711 20.4058 13.7878 21.1206 13.7878C23.1292 13.7878 24.1031 15.3212 24.1031 17.1881C24.1031 18.9058 23.2405 20.5254 21.3546 20.5254C20.4919 20.5254 19.5677 20.3158 19.1485 19.4748H19.1241V20.353H17.793V11.5258L17.793 11.5256Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask2_1801_111)">
                <mask id="mask3_1801_111" maskUnits="userSpaceOnUse" x="-1" y="0" width="34" height="32">
                  <path d="M-0.000976562 32H32.999V3.8147e-06H-0.000976562V32Z" fill="white" />
                </mask>
                <g mask="url(#mask3_1801_111)">
                  <path className={css.spec} d="M17.4717 20.8379H24.4264V11.2131H17.4717V20.8379Z" fill="#FF4622" />
                </g>
              </g>
              <mask id="mask4_1801_111" maskUnits="userSpaceOnUse" x="24" y="13" width="7" height="8">
                <path
                  d="M29.284 16.1C29.1731 15.3211 28.6063 14.9005 27.8299 14.9005C27.1024 14.9005 26.0798 15.284 26.0798 17.225C26.0798 18.2883 26.5479 19.4134 27.7682 19.4134C28.5815 19.4134 29.1482 18.8688 29.284 17.954H30.6889C30.4301 19.6106 29.4073 20.5254 27.7682 20.5254C25.7716 20.5254 24.6748 19.104 24.6748 17.225C24.6748 15.2965 25.7224 13.7881 27.8175 13.7881C29.2964 13.7881 30.5538 14.5299 30.6889 16.1001H29.2864L29.284 16.1Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask4_1801_111)">
                <mask id="mask5_1801_111" maskUnits="userSpaceOnUse" x="-1" y="0" width="34" height="33">
                  <path d="M-0.00195312 32.0002H32.998V0.000247955H-0.00195312V32.0002Z" fill="white" />
                </mask>
                <g mask="url(#mask5_1801_111)">
                  <path className={css.spec} d="M24.3545 20.8381H31.0131V13.4759H24.3545V20.8381Z" fill="#FF4622" />
                </g>
              </g>
              <mask id="mask6_1801_111" maskUnits="userSpaceOnUse" x="2" y="11" width="16" height="10">
                <path d="M12.883 20.3333H2.3457L7.26645 11.5251H17.8038L12.883 20.3333Z" fill="white" />
              </mask>
              <g mask="url(#mask6_1801_111)">
                <mask id="mask7_1801_111" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="32">
                  <path d="M0 32H33V3.8147e-06H0V32Z" fill="white" />
                </mask>
                <g mask="url(#mask7_1801_111)">
                  <path d="M2.02344 20.646H18.126V11.2129H2.02344V20.646Z" className={css.spec} fill="#FF4622" />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_1801_111">
                <rect width="33" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <span>digital copy</span>
      </div>
    </a>
  )
}
